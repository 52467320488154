import React, { Fragment, useEffect, useState } from "react";
import { StyledAddressChange } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantConfig } from "features/config/configSlice";
import {
  addAddress,
  getAddress,
  getServiceabileCheck,
  getServiceability,
  getTempServiceability,
  updateServiceCheck,
  updateServiceability,
  updateTempServiceability,
} from "features/address/addresSlice";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { addEmail, addPhone } from "features/user/userSlice";
import { serviceabiltyCheck } from "utils/api";
import { ClipLoader } from "react-spinners";

function ErrorModal({
  wrongAddress,
  userInfoError,
  selectedItem,
  format,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  setWrongAddress,
  setUserInfoError,
  userPhoneNumber,
  setUserPhoneNumber,
  userEmail,
  setUserEmail,
  setAddressChange,
  addressDetails,
  wrongAddressStatement,
}) {
  const merchantConfig = useSelector(getMerchantConfig);

  const [state, setState] = useState(addressDetails.state);
  const [city, setCity] = useState(addressDetails.city);
  const [addressLine1, setAddressLine1] = useState(addressDetails.addressLine1);
  const [addressLine2, setAddressLine2] = useState(addressDetails.addressLine2);
  const [pincode, setPincode] = useState(addressDetails.pincode);
  const [serviceableLoader, setServiceableLoader] = useState(false);

  const serviceability = useSelector(getServiceability);
  const serviceableCheck = useSelector(getServiceabileCheck);
  const tempServiceablity = useSelector(getTempServiceability);
  const updatedAddress = useSelector(getAddress);
  const dispatch = useDispatch();
  const { handleSubmit } = useForm();
  toast.configure();

  useEffect(() => {
    dispatch(updateTempServiceability(serviceableCheck));
    // eslint-disable-next-line
  }, [serviceableCheck]);

  const checkServiceabilty = (pin, edit) => {
    setServiceableLoader(true);
    serviceabiltyCheck(pin, merchantConfig.merchant_id)
      .then((resp) => {
        dispatch(updateServiceability(true));
        if (edit) {
          dispatch(updateTempServiceability(resp.data.serviceable));
          //   setTempServiceabilty(resp.data.serviceable);
        } else {
          dispatch(updateServiceCheck(resp.data.serviceable));
          //   setServiceableCheck(resp.data.serviceable);
        }
        // setAddressLoader(false);
        // setServiceableLoader(false);
        // setAddressApiLoader(false);
      })
      .catch((err) => {
        // setAddressApiLoader(true);
      })
      .finally(() => {
        setServiceableLoader(false);
      });
  };

  const addressHandler = () => {
    const isValidAddressLine2 = addressLine2 ?? "";
    const totalLength = (addressLine1 + isValidAddressLine2).length;
    if (
      !(
        format.test(pincode) ||
        format.test(addressLine1) ||
        format.test(addressLine2) ||
        format.test(state) ||
        format.test(city) ||
        addressLine1.length < 15 ||
        addressLine1.length > 80 ||
        (isValidAddressLine2 !== "" &&
          isValidAddressLine2.length < 10 &&
          isValidAddressLine2.length > 70) ||
        totalLength > 150
      )
    ) {
      setAddressChange(false);
      dispatch(updateServiceCheck(tempServiceablity));
      dispatch(
        addAddress({
          pincode,
          firstName,
          lastName,
          addressLine1,
          addressLine2,
          state,
          city,
        })
      );
      dispatch(addPhone(userPhoneNumber));
      dispatch(addEmail(userEmail));
      setWrongAddress(false);
      setUserInfoError(false);
    } else {
      toast.error(
        addressLine1.length > 80
          ? "Please provide a maximum of 80 characters in address line 1."
          : isValidAddressLine2 !== "" && isValidAddressLine2.length > 70
          ? "Please provide a maximum of 70 characters in address line 2."
          : isValidAddressLine2 !== "" && isValidAddressLine2.length < 10
          ? "Please provide a minimum of 10 characters in address line 2."
          : addressLine1.length < 15
          ? "Please provide a minimum of 15 characters in address line 1."
          : totalLength > 150
          ? "Address should not exceed 150 characters in total."
          : "Please avoid using special characters in the address details",
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        }
      );
    }
  };

  useEffect(() => {
    document.getElementById("addressChange").scrollIntoView();
  }, []);

  return (
    <StyledAddressChange serviceableCheck={serviceableCheck} id="addressChange">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
          padding: "1rem 1.5rem",
        }}
      >
        <p style={{ fontSize: "1rem", fontWeight: "500", color: "#333" }}>
          Your Pickup & Delivery Address
        </p>
        {!wrongAddress && (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (
                (!(
                  format.test(pincode) ||
                  format.test(addressLine1) ||
                  format.test(addressLine2) ||
                  format.test(state) ||
                  format.test(city)
                ) &&
                  wrongAddress) ||
                !wrongAddress
              ) {
                setAddressChange(false);
                dispatch(
                  addAddress({
                    pincode: updatedAddress.pincode,
                    firstName: updatedAddress.customer_first_name,
                    lastName: updatedAddress.customer_last_name,
                    addressLine1: updatedAddress.addressLine1,
                    addressLine2: updatedAddress.addressLine2,
                    state: updatedAddress.state,
                    city: updatedAddress.city,
                  })
                );
                dispatch(addPhone(userPhoneNumber));
                dispatch(addEmail(userEmail));
                setPincode(updatedAddress.pincode);
                dispatch(updateTempServiceability(serviceableCheck));
              } else {
                toast.error(
                  "Please fill all the fields without special characters ",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                  }
                );
              }
            }}
          >
            {" "}
            <CloseIcon
              style={{ width: "24px", height: "24px", stroke: "#333" }}
            />
          </p>
        )}
      </div>
      {wrongAddress && (
        <div style={{ padding: "1rem 1.rem 0px" }}>
          <p
            style={{
              color: "red",
              padding: "1rem 20px",
              // border: "1px solid red",
              // background: "#f4b3b3",
              marginBottom: "0px",
              borderRadius: "4px",
              fontWeight: "500",
            }}
          >
            {wrongAddressStatement}
          </p>
        </div>
      )}
      {userInfoError && (
        <div style={{ padding: "10px 24px 0px" }}>
          <p
            style={{
              color: "red",
              padding: "4px 0px",
              // border: "1px solid red",
              // background: "#f4b3b3",
              marginBottom: "0px",
              borderRadius: "4px",
              width: "max-content",
              fontWeight: "500",
            }}
          >
            Please fill all the fields*
          </p>
        </div>
      )}
      <form
        onSubmit={handleSubmit(addressHandler)}
        style={{ margin: "10px 0px", padding: "0px 1.5rem" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            overflowY: "auto",
          }}
        >
          <TextField
            name="pincode"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
            variant="outlined"
            label="PIN Code"
            defaultValue={
              selectedItem[0].shipping_address.zip ||
              selectedItem[0].billing_address.zip
            }
            inputProps={{ type: "text" }}
            style={{ marginTop: "10px" }}
          />
          <p
            style={{
              fontSize: "14px",
              color: "#318CE7",
              marginLeft: "1rem",
              cursor: "pointer",
              marginTop: "6px",
            }}
            onClick={() => {
              checkServiceabilty(pincode, true);
              setAddressLine1("");
              setAddressLine2("");
              setState("");
              setCity("");
            }}
          >
            Check serviceability
            {serviceableLoader && (
              <ClipLoader
                color="#318CE7"
                size={15}
                cssOverride={{ marginLeft: "10px", marginTop: "10px" }}
              />
            )}
          </p>
        </div>

        {serviceability && (
          <Fragment>
            {!tempServiceablity && (
              // <p
              //   style={{
              //     color: "#333333B2",
              //     width: "100%",
              //     margin: "10px 0px",
              //     fontSize: "14px",
              //   }}
              // >
              //   {" "}
              //   Yayy ! Your PIN Code is eligible for pickup and delivery. <br />
              // </p>
              <p
                style={{
                  color: "#333333",
                  width: "100%",
                  margin: "10px 0px",
                  fontSize: "14px",
                }}
              >
                Your PIN Code is ineligible for pickup of products. <br />
                Please provide address for delivery.{" "}
              </p>
            )}
            <div style={{ display: "flex" }}>
              <TextField
                name="state"
                variant="outlined"
                value={state}
                onChange={(e) => setState(e.target.value)}
                label="State"
                required
                fullWidth
                inputProps={{ type: "text" }}
                style={{ marginTop: "1rem" }}
              />
              <TextField
                name="city"
                variant="outlined"
                onChange={(e) => setCity(e.target.value)}
                label="City"
                value={city}
                required
                fullWidth
                inputProps={{ type: "text" }}
                style={{ marginTop: "1rem", marginLeft: "10px" }}
              />
            </div>
            <div style={{ display: "flex" }}>
              <TextField
                name="first_name"
                variant="outlined"
                onChange={(e) => setFirstName(e.target.value)}
                label="First Name"
                value={firstName}
                required
                fullWidth
                inputProps={{ type: "text" }}
                style={{ marginTop: "1rem" }}
                error={firstName === ""}
                helperText={firstName === "" ? "Please provide first name" : ""}
              />
              <TextField
                name="last_name"
                variant="outlined"
                onChange={(e) => setLastName(e.target.value)}
                label="Last Name"
                value={lastName}
                required
                fullWidth
                inputProps={{ type: "text" }}
                style={{ marginTop: "1rem", marginLeft: "10px" }}
                error={lastName === ""}
                helperText={lastName === "" ? "Please provide last name" : ""}
              />
            </div>
            <div style={{ display: "flex" }}>
              <TextField
                name="email"
                variant="outlined"
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
                label="Email"
                required
                fullWidth
                inputProps={{ type: "email" }}
                style={{ marginTop: "1rem" }}
                error={userEmail === ""}
                helperText={userEmail === "" ? "Please provide an email" : ""}
              />
              <TextField
                name="phone_number"
                variant="outlined"
                value={userPhoneNumber}
                disabled={
                  userPhoneNumber &&
                  userPhoneNumber !== null &&
                  userPhoneNumber.length > 9
                    ? true
                    : false
                }
                onChange={(e) => {
                  setUserPhoneNumber(e.target.value);
                }}
                label="Phone Number"
                required
                fullWidth
                inputProps={{ type: "text" }}
                style={{ marginTop: "1rem", marginLeft: "10px" }}
                error={
                  userPhoneNumber &&
                  userPhoneNumber !== null &&
                  userPhoneNumber === ""
                }
                helperText={
                  userPhoneNumber &&
                  userPhoneNumber !== null &&
                  userPhoneNumber === ""
                    ? "Please provide a valid phone number"
                    : ""
                }
              />
            </div>
            <TextField
              name="address_line1"
              variant="outlined"
              onChange={(e) => setAddressLine1(e.target.value)}
              label="Address Line 1"
              value={addressLine1}
              required
              fullWidth
              defaultValue=""
              inputProps={{ type: "text", minLength: 15 }}
              style={{ marginTop: "1rem" }}
              error={
                (addressLine1 !== null && addressLine1.length < 15) ||
                addressLine1.length > 80
              }
              helperText={
                addressLine1 !== null && addressLine1.length < 15
                  ? "Please provide a minimum of 15 characters"
                  : addressLine1 !== null && addressLine1.length > 80
                  ? "Please provide a maximum of 80 characters"
                  : ""
              }
            />
            <TextField
              name="address_line2"
              variant="outlined"
              onChange={(e) => setAddressLine2(e.target.value)}
              label="Address Line 2"
              value={addressLine2}
              fullWidth
              defaultValue=""
              inputProps={{ type: "text" }}
              style={{ marginTop: "1rem" }}
              error={
                (addressLine2 &&
                  addressLine2 !== null &&
                  addressLine2.length < 10) ||
                (addressLine2 &&
                  addressLine2 !== null &&
                  addressLine2.length > 70)
              }
              helperText={
                addressLine2 &&
                addressLine2 !== null &&
                addressLine2.length < 10
                  ? "Please provide a minimum of 10 characters"
                  : addressLine2 &&
                    addressLine2 !== null &&
                    addressLine2.length < 10
                  ? "Please provide a maximum of 70 characters"
                  : ""
              }
            />

            <Button
              color="primary"
              type="submit"
              style={{
                padding: "1rem",
                borderRadius: !merchantConfig.use_rounded_edge_buttons
                  ? "0px"
                  : "4px",
                margin: "1rem auto",
                display: "block",
                border: `1px solid ${merchantConfig.primary_theme_color}80`,
                cursor: "pointer",
                textTransform: "capitalize",
              }}
            >
              {userInfoError ? "Save & Continue" : "Save Address"}
            </Button>
          </Fragment>
        )}
      </form>
    </StyledAddressChange>
  );
}

export default ErrorModal;
