import styled, { keyframes } from "styled-components";

const animation = keyframes`
    0%{
        opacity: 0;
        transform: translateY(25%);
    }
    100%{
        opacity: 1;
        transform: trabnslateY(0);
    }
`;

const addToCart = keyframes`
  0%{
    bottom: 50px;
    right: 100px;
  }
  50%{
    bottom: 50%;
    right: 50%;
  }
  100%{
    bottom: 75%;
    right: 85%;
  }
`;

export const StyledProducts = styled.div`
  display: flex;
  border-radius: 4px;
  width: 100%;
  font-size: 1rem !important;

  div {
    width: 100%;
  }

  .product-image {
    object-fit: cover;
    height: 100%;
    background: #fff;
    object-position: left;
    max-height: 150px;
  }

  .product-details {
    padding: 0px 15px;
  }

  .radio-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  .margin {
    margin: 10px 0px;
  }

  @media (max-width: 800px) {
    font-size: 14px !important;
    height: 140px;

    .product-details {
      padding: 20px 12px;
    }

    .top-banner {
      display: none;
    }

    .product-image {
      margin-top: 20px;
      margin-left: 15px;
      border-radius: 6px;
      width: 100px;
      height: 120px;
    }

    .radio-buttons {
      display: none;
    }

    .margin {
      margin: 10px 0px;
    }
  }
`;

export const StyledProductsWrapper = styled.div`
  padding: ${(props) =>
    props.location === "/products" && props.productConsent
      ? "140px 5.5% 100px 5.5%"
      : props.location === "/products" && !props.productConsent
      ? "100px 5.5% 100px 5.5%"
      : props.returnedProducts !== undefined &&
        props.returnedProducts.length > 0
      ? "20px 0px 100px 11%"
      : "25px 11% 0px"};

  .products-wrapper {
    width: ${(props) => (props.location === "/products" ? "50%" : "100%")};
    background: #fff;

    .add-to-cart-image {
      position: absolute;
      z-index: 30;
      height: 50px;
      width: 50px;
      animation: ${addToCart} 700ms linear;
    }

    .products-return {
      margin-top: 1rem;
    }
    .accordion {
      max-width: 700px;
      border-radius: 0px;
      .MuiAccordionDetails-root {
        padding: 0px;
      }
    }

    .cart-wrapper {
      border-bottom: ${(props) =>
        props.outOfStock !== undefined &&
        props.outOfStock !== null &&
        props.returnedProducts
          .filter((a) => a.variant_id)
          .indexOf(props.outOfStock)
          ? `1px solid red`
          : "1px solid #11111133"};

      .exchange-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .exchange-box {
          display: flex;
          padding: 0px 0px;
          border-radius: 4px;
          flex-direction: column;
          flex: 0.45;

          .mob-ex {
            font-size: 16px;
            margin: 0px 0px 10px;
            color: #333;
            font-weight: 500;
          }

          .upto-refund,
          .conditions {
            font-size: 16px;
          }

          .image-preview {
            width: 60px;
            height: 60px;
            object-fit: contain;
            object-position: left;
            border-radius: 4px;
          }

          .description,
          .quantity {
            font-size: 16px;
            margin: 10px 0;
            color: #333333b2;
          }

          .title,
          .price {
            font-size: 16px;
            margin: 10px 0;
            font-weight: 500;
            color: #333;
          }

          .stock {
            color: red;
            margin-left: auto;
          }

          .details {
            padding: 0px 0px;
            position: relative;
            width: 100%;
          }
        }

        .return-icon {
          display: flex;
          align-items: center;
          flex: 0.1;
          margin: 0 20px;
        }
      }

      .return-type,
      .return-reason {
        font-size: 1rem;
      }
    }

    .select-products {
      background: transparent;
      padding: 1rem 0px;
      margin-bottom: 0;
      font-weight: 600;
    }

    .button-container {
      display: flex;
      justify-content: center;
      margin: 20px 0rem 4px;
    }

    .incentivise-message {
      padding: 0 0rem 10px;
      font-weight: 600;
      font-size: 13px;
      margin: 0px;
    }

    .info-message {
      color: #333333b2;
      font-size: 14px;
      padding: 1rem 0rem;
    }

    .mob-view-cart-btn {
      position: fixed;
      bottom: ${(props) => (props.location === "/products" ? "30px" : "40px")};
      left: 0;
      width: 100%;
      z-index: 20;
      background: #fff;
      padding: 10px 0px;
      box-shadow: 0px -3px 0px rgba(0, 0, 0, 0.1);

      button {
        margin: 10px 30px 0px auto;
        max-width: 400px;
        padding: 10px 0px;
        display: flex;
      }
    }
  }

  .products-skeleton {
    display: flex;
    width: ${(props) => (props.location === "/products" ? "45%" : "100%")};
    border: 1px solid rgba(17, 17, 17, 0.2);
    margin-left: ${(props) =>
      props.location === "/products" ? "40px" : "0px"};
    margin-top: 40px;

    .image-skeleton {
      width: 220px;
      height: 220px;
    }

    .text-skeleton {
      width: 250px;
      margin-top: 10px;
    }
  }
  .select {
    font-size: 1rem;
    color: #333;
    margin: 20px 0px;
    font-weight: 600;
  }

  .active {
    display: block;
  }

  @media (max-width: 800px) {
    padding: ${(props) =>
      props.location === "/products" &&
      props.productConsent &&
      props.returnedProducts.length > 0
        ? "180px 0px 20px"
        : props.location === "/products" && props.productConsent
        ? "130px 0px 20px"
        : props.location === "/products" &&
          !props.productConsent &&
          props.returnedProducts.length > 0
        ? "110px 0px 20px"
        : props.location === "/products" && !props.productConsent
        ? "60px 0px 20px"
        : "10px 0px 20px"};

    .products-wrapper {
      width: 100%;

      .products-return {
        margin-top: 0px;
      }

      .select-products {
        background: #f1f1f1;
        padding: 1rem;
      }

      .accordion {
        position: fixed;
        z-index: 10;
        width: 100%;
        top: ${(props) => (props.productConsent > 0 ? "130px" : "60px")};
      }

      .button-container {
        display: flex;
        justify-content: center;
        margin: 20px 1rem 4px;
      }

      .incentivise-message {
        padding: 0 1rem 10px;
        font-size: 12px;
      }

      .info-message {
        font-size: 12px;
        padding: 1rem;
      }

      .cart-wrapper {
        border-bottom: none;
        .exchange-wrapper {
          .exchange-box {
            .mob-ex,
            .upto-refund,
            .conditions,
            .description,
            .quantity {
              font-size: 12px;
            }
            .title,
            .price {
              font-size: 14px;
            }
          }

          .return-icon {
            margin: 0 10px;
          }
        }

        .return-type,
        .return-reason {
          font-size: 14px;
        }
      }

      .mob-view-cart-btn {
        display: flex;
        position: fixed;
        bottom: 20px;
        margin-bottom: 1rem;
        width: 100%;
        box-shadow: none;
        padding: 0;

        button {
          margin: 0px 1rem;
          padding: 10px 0px;
          max-width: 100%;
        }
      }
    }

    .select,
    .help {
      padding: 0px 15px;
    }

    .select {
      font-size: 14px;
      margin: 0px;
      margin-bottom: 12px;
    }

    .products-skeleton {
      width: 90%;
      margin-left: 20px;
      margin-top: 20px;

      .image-skeleton {
        width: 140px;
        height: 140px;
      }

      .text-skeleton {
        width: 120px;
        margin-top: 0px;
      }
    }
  }
`;

export const StyledMobButtons = styled.div`
  padding: 0px 1rem;
  @media (min-width: 800px) {
    display: none;
  }
`;

export const StyledReasonWrapper = styled.div`
  padding: 20px 0px;
  padding-top: 0px;
  border: 1px solid #11111133;
  overflow-y: scroll;
  height: 85vh;
  background: #fff;
  border-radius: ${(props) => (props.rounded ? "10px 10px 0px 0px" : "0px")};

  :hover {
    overflow-y: scroll;
  }

  .product_title {
    font-size: 14px;
  }

  .reason-form {
    font-size: 12px;
    padding: 10px 24px;
  }

  .reasons {
    display: ${(props) =>
      props.selectedReason && props.selectedReason !== null ? "none" : "block"};
  }

  .preference {
    margin: 10px 0px !important;
  }

  .different-skeleton {
    min-width: 150px;
    max-width: 400px;
  }

  .diff-prod-buttons {
    .exchange-button-web {
      display: none;
    }

    .exchange-button-mob {
      display: block;
      position: absolute;
      z-index: 25;
      bottom: 0;
      background: #fff;
      padding: 10px 16px;
      box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.12);
      overflow: hidden;
      width: 100%;
    }

    .back-button {
      display: none;
    }
  }

  .collections-section {
    padding: 0px 0px 10px 20px;
    overflow: auto;
    margin-right: 1rem;
    display: flex;

    ::-webkit-scrollbar {
      height: 2px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.primary_color};
    }
  }

  @media (min-width: 800px) {
    animation: ${animation} 1s linear;
    max-width: 600px;
    margin: auto;
    height: 70vh;
    border-radius: ${(props) => (props.rounded ? "10px" : "0px")};

    .reason-form {
      font-size: 16px;
    }
    .preference {
      margin: 20px 0px;
    }
    .product_title {
      font-size: 16px;
    }
    .different-skeleton {
      .diif-image {
        width: 100%;
        margin: 10px 0px 5px;
        min-width: 200px;
      }
    }

    .diff-prod-buttons {
      .exchange-button-web {
        margin: 20px auto 20px;
        display: block;
      }

      .exchange-button-mob {
        display: none;
      }

      .back-button {
        display: block;
        margin: 20px auto;
      }
    }
  }
`;

export const StyledDifferentProduct = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;

  .image-container {
    width: 100%;
    height: 300px;
    object-fit: contain;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  span {
    height: inherit;
  }

  .product-image {
    height: 100%;
    border-radius: 0px;
    margin: 0px;
  }

  .product-details {
    margin-left: 0px;
    padding: 1rem 1rem 0px;

    .product-title {
      color: #333;
      margin: 6px 0px;
      font-size: 14px;
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
    padding: 10px 20px;

    .image-container {
      width: auto;
      height: auto;
    }

    .product-image {
      width: 150px;
      height: 100%;
      max-height: 200px;
      border-radius: 4px;
      object-fit: contain;
    }

    .product-details {
      margin-left: 6px;
      padding: 0px 1rem;

      .product-title {
        font-size: 16px;
      }
    }
  }
`;

export const StyledVariantSelect = styled.div`
  padding: 0px 1rem 60px;

  @media (min-width: 800px) {
    padding: 0px 20px 60px;
  }
`;
